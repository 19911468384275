/* ---------------------------------
     HEADER
--------------------------------- */

header {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  z-index: 100;
}

header .heading-wrapper {
  border-radius: 5px;
  padding: 30px 40px 20px;
  background: #ffad01;
  color: #fff;
}

header .info {
  margin-bottom: 10px;
}

header .info .icon {
  float: left;
  font-size: 30px;
}

header .info .right-area {
  margin-left: 45px;
}

header .downlad-btn {
  padding: 15px 30px;
  border-radius: 4px;
  float: right;
  margin-top: 30px;
  box-shadow: inset 0 0px 4px rgba(0, 0, 0, 0.3);
  background: #ffad01;
  color: #fff;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

header .downlad-btn:hover {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}
