/* ---------------------------------
   PROJECTS
--------------------------------- */

.learn-more {
  h5 {
    color: aquamarine;
    #more-project {
      text-decoration: underline;
    }
  }
}
