.CircularProgressbar .CircularProgressbar-path {
  stroke: url(#progressBarColor);
}

.circular-progress {
  height: 200px;
  width: 200px;
  padding: 1em;
  text-align: center;
  b {
    font-size: 25px;
  }
  p {
    font-size: small;
    color: black;
  }
}
