/* ---------------------------------
     INTRO SECTION
--------------------------------- */

.intro-section {
  padding-top: 200px;
  position: relative;
  background-size: cover;
  z-index: 1;
  background-image: url(../../images/slider-1-1920x1080.jpg);
}

.intro-section:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
}

.intro-section:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  z-index: -1;
  background: #fff;
}

.intro {
  padding: 0 20px;
  margin-top: 100px;
  text-align: center;
  background: #fff;
}

.intro .profile-img {
  height: 200px;
  width: 200px;
  border-radius: 100px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  top: -100px;
  margin-bottom: -70px;
}

.intro .information > li {
  display: block;
  margin: 5px 0;
}

.intro .social-icons > li > a {
  height: 35px;
  line-height: 35px;
  width: 35px;
  border-radius: 20px;
  overflow: hidden;
  z-index: 1;
  position: relative;
  margin: 0 5px 5px;
  background: #887392;
  color: #fff;
}

.intro .social-icons > li > a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
  border-radius: 20px;
  background: #ffa804;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.intro .social-icons > li > a:hover:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}
