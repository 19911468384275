/* ---------------------------------
     WORK EXPERIENCE SECTION
--------------------------------- */

.experience-section {
  background: #28023d;
  color: #fff;
}

.experience .list > li {
  display: block;
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
}

.experience .list > li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 10px;
  width: 10px;
  margin-top: -5px;
  display: block;
  border-radius: 10px;
  border: 2px solid #fff;
}
