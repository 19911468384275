/* ---------------------------------
   CONTACT ME
--------------------------------- */

.addresses {
  padding: 1em;
  p {
    color: #28023d;
  }
  span {
    color: #28023d;
  }
}
.icon {
  padding-right: 8px;
}

#send_btn {
  background-color: #28023d;
}
