/*
====================================================

* 	[Master Stylesheet]
	
	Theme Name :  Portfolio
	Version    :  v1.0
	Author     :  Injamul Mohammad Mollah
	Author URI :  github.com/mrinjamul

====================================================


/* ---------------------------------
1. PRIMARY STYLES
--------------------------------- */

html {
  font-size: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
  touch-action: manipulation;
}

body {
  font-size: 16px;
  font-family: "Poppins", serif;
  width: 100%;
  height: 100%;
  margin: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
span,
li,
img,
inpot,
button {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
  font-weight: inherit;
}

h1,
h2,
h3 {
  font-family: "Poppins", cursive;
}

p {
  line-height: 1.6;
  font-size: 1.05em;
  font-weight: 400;
  color: #777;
}

h1 {
  font-size: 4em;
  line-height: 1;
}
h2 {
  font-size: 2.2em;
  line-height: 1.1;
}
h3 {
  font-size: 1.8em;
}
h4 {
  font-size: 1.3em;
}
h5 {
  font-size: 1em;
}
h6 {
  font-size: 0.9em;
  letter-spacing: 1px;
}

a,
button {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  line-height: 1;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

a:focus,
a:active,
a:hover,
button:focus,
button:active,
button:hover,
a b.light-color:hover {
  text-decoration: none;
  color: #ffa804;
}

b {
  font-weight: 600;
}

img {
  width: 100%;
}

li {
  list-style: none;
  display: inline-block;
}

span {
  display: inline-block;
}

button {
  outline: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

.icon {
  font-size: 1.1em;
  display: inline-block;
  line-height: inherit;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  line-height: inherit;
}

b.max-bold {
  font-weight: 700;
}

/* ---------------------------------
2. COMMONS FOR PAGE DESIGN
--------------------------------- */

.section {
  padding: 100px 0 50px;
}

.section .heading {
  margin-bottom: 60px;
}

.section .heading:before {
  content: "";
  height: 10px;
  width: 50px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: block;
  background: #ffa804;
}

.margin-t-10 {
  margin-top: 10px;
}

.margin-b-30 {
  margin-bottom: 30px;
}
.margin-b-50 {
  margin-bottom: 50px;
}

.margin-tb-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.font-yellow {
  color: #ffa600;
}

.font-semi-white {
  color: #ddd;
}

.font-lite-black {
  color: #999;
}

/* ---------------------------------
   RADIAL PROGRESS  SECTION
--------------------------------- */
/* 
.radial-progress {
  position: relative;
  max-width: 200px;
  margin: 0 auto;
}

.radial-progress .progressbar-text {
  font-size: 2.2em;
  font-weight: 500;
  padding-bottom: 25px !important;
  color: #333 !important;
}

.radial-progress .progressbar-text:after {
  content: "%";
}

.radial-progress .progress-title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  letter-spacing: 0;
  text-align: center;
  transform: translate(-50%, 15px);
  color: #777;
} */

/* RADIAL PREOGRESS SECTION */
/* 
.radial-progress {
  position: relative;
  max-width: 200px;
  margin: 0 auto;
}

.radial-progress .progressbar-text {
  font-size: 2.2em;
  font-weight: 500;
  padding-bottom: 25px !important;
  color: #333 !important;
}

.radial-progress .progressbar-text:after {
  content: "%";
}

.radial-progress .progress-title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  letter-spacing: 0;
  text-align: center;
  transform: translate(-50%, 15px);
  color: #777;
} 
*/
