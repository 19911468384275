

/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
	/* ---------------------------------
	9. COUNTER SECTION
	--------------------------------- */

	.counter-section{ text-align: center; padding: 50px 0 20px; }

	.counter{ display: inline-block; }
	
	.counter .desc{ text-align: left; }
}

/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 992px) {
	
	/* ---------------------------------
	4. INTRO SECTION
	--------------------------------- */

	.intro-section{ padding-top: 250px; }
	
}

/* Screens Resolution : 767px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 767px) {
	
	/* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

	p{ line-height: 1.4; }

	h1{ font-size: 3em; }
	h2{ font-size: 2em; }
	h3{ font-size: 1.8em; }


	
	/* ---------------------------------
	2. COMMONS FOR PAGE DESIGN
	--------------------------------- */

	.section{ padding: 50px 0 0px; }

	.section .heading{ margin-bottom: 30px; }

	
	/* ---------------------------------
	4. INTRO SECTION
	--------------------------------- */

	.intro-section{ padding-top: 350px; }


	/* ---------------------------------
	5. PORFOLIO SECTION
	--------------------------------- */

	.portfolio-section{ padding-bottom: 0; }
	
	.portfolio-section .portfolioFilter{ margin: 0px 0 10px; }
	
	.portfolio-section .portfolioContainer{ margin-left: 0; margin-right: 0; }

	.portfolio-section .p-item{ width: 50%; padding: 0 15px 0px; }

	.portfolio-section .p-item{ max-width: 400px; }

	
	/* ---------------------------------
	8. EDUCATION SECTION
	--------------------------------- */
	
	.education-wrapper:after,
	.education-wrapper .education:after{ display: none; }

}

/* Screens Resolution : 575px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 575px) {
	
	/* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

	body{ font-size: 14px;  }
	

	/* ---------------------------------
	5. PORFOLIO SECTION
	--------------------------------- */
	
	.portfolio-section .p-item{ width: 100%; }
	
}

/* Screens Resolution : 479px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 479px) {


}

/* Screens Resolution : 359px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 359px) {
	
	/* ---------------------------------
	3. HEADER
	--------------------------------- */

	header .heading-wrapper{  padding: 30px 20px 20px; }

	header .info .icon{ font-size: 25px; }

	header .info .right-area{ margin-left: 35px; }

}

/* Screens Resolution : 290px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 290px) {

	
}

/* Screens Resolution : 290px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 260px) {
	
	/* ---------------------------------
	3. HEADER
	--------------------------------- */

	header .heading-wrapper{  padding: 10px 15px 0px; }

	header .info .icon{ font-size: 15px; }

	header .info .right-area{ margin-left: 20px; }
	
	/* ---------------------------------
	4. INTRO SECTION
	--------------------------------- */

	.intro .profile-img{ width: 80%; height: auto; top: 0; margin-bottom: 10px; }
	
	.intro{ padding: 20px 10px 0; margin-right: -15px; margin-left: -15px; }
	
}