/* ---------------------------------
     FOOTER
--------------------------------- */

.copyright {
  text-align: center;
  padding: 30px 0;
  background: #28023d;
}
.easter {
    a,i {
        &:hover {
            color: red;
        }
    }
}
